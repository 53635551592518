var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var StyledAsyncPaginate = function (customStyles, customContainerStyles, customPlaceHolderStyles) {
    if (customStyles === void 0) { customStyles = {}; }
    return ({
        indicatorsContainer: function () { return ({ display: 'none' }); },
        control: function (provided) { return (__assign(__assign(__assign({}, provided), { height: '100%', width: '100%', borderColor: '#DDE0E5', borderRadius: '8px', '&:hover': {
                outline: 'none !important',
                border: '1.5px solid black !important',
            } }), customStyles)); },
        menu: function (provided) { return (__assign(__assign({}, provided), { zIndex: 99999999999, marginTop: '10px' })); },
        container: function (provided) { return (__assign(__assign({}, provided), customContainerStyles)); },
        placeholder: function (provided) { return (__assign(__assign(__assign({}, provided), { color: '#919EAB' }), customPlaceHolderStyles)); },
    });
};
